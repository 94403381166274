import React from "react";

const SvgMatthewCopy = () => {
  return (
    <svg
      className="firstName-flipped"
      viewBox="0 0 446 70"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
    >
      <g
        id="svgGroup"
        strokeLinecap="round"
        fillRule="evenodd"
        fontSize="9pt"
        stroke="#bdbdbd"
        strokeWidth="0.25mm"
        fill="#bdbdbd"
      >
        <path
          className="letter"
          d="M 13.1 30 L 13.1 73 L 0 73 L 0 3 L 13.9 3 L 34.2 48.4 L 34.4 48.4 L 54.7 3 L 68.7 3 L 68.7 73 L 55.5 73 L 55.5 30 L 55.3 30 L 35.4 73 L 33.2 73 L 13.3 30 L 13.1 30 Z"
          id="0"
        />
        <path
          className="letter"
          d="M 124.8 39.4 L 124.8 73 L 113.9 73 L 113.9 67.9 L 113.7 67.9 A 0.168 0.168 0 0 1 113.684 67.957 Q 113.616 68.122 113.25 68.65 A 5.3 5.3 0 0 1 112.926 69.067 Q 112.473 69.597 111.7 70.3 A 13.633 13.633 0 0 1 110.362 71.364 A 18.079 18.079 0 0 1 109.05 72.2 A 11.76 11.76 0 0 1 107.747 72.844 Q 107.063 73.135 106.247 73.401 A 26.77 26.77 0 0 1 104.9 73.8 A 20.507 20.507 0 0 1 100.956 74.445 A 24.341 24.341 0 0 1 99.3 74.5 A 30.884 30.884 0 0 1 93.842 74.049 Q 88.514 73.091 85.15 70.1 A 14.304 14.304 0 0 1 80.219 59.653 A 18.606 18.606 0 0 1 80.2 58.8 A 18.264 18.264 0 0 1 80.657 54.587 Q 81.462 51.191 83.683 48.897 A 11.582 11.582 0 0 1 84.25 48.35 A 16.756 16.756 0 0 1 87.353 46.191 Q 90.664 44.355 95.831 42.915 A 72.415 72.415 0 0 1 98.2 42.3 A 216.426 216.426 0 0 0 101.465 41.476 Q 106.283 40.217 108.426 39.356 A 8.608 8.608 0 0 0 109.599 38.8 Q 111.9 37.4 111.9 35.1 A 4.517 4.517 0 0 0 110.293 31.584 A 6.596 6.596 0 0 0 109.6 31.05 Q 107.971 29.952 105.288 29.632 A 20.202 20.202 0 0 0 102.9 29.5 Q 97.9 29.5 95.5 31.65 A 10.653 10.653 0 0 0 93.622 33.913 Q 92.92 35.024 92.345 36.421 A 22.935 22.935 0 0 0 91.7 38.2 L 80.9 33.7 Q 82.9 26.8 88.5 22.65 A 20.488 20.488 0 0 1 96.439 19.133 A 29.343 29.343 0 0 1 102.7 18.5 A 30.783 30.783 0 0 1 109.53 19.217 A 21.238 21.238 0 0 1 118.6 23.6 Q 124.244 28.242 124.75 37.525 A 34.43 34.43 0 0 1 124.8 39.4 Z M 112.3 55.5 L 112.3 46.3 L 112.1 46.3 A 20.834 20.834 0 0 1 111.835 46.73 Q 111.486 47.281 111.267 47.528 A 1.643 1.643 0 0 1 111.2 47.6 Q 109.71 49.09 100.915 51.272 A 152.286 152.286 0 0 1 100.8 51.3 A 31.759 31.759 0 0 0 98.638 51.881 Q 96.526 52.536 95.271 53.334 A 5.845 5.845 0 0 0 94.4 54 A 5.334 5.334 0 0 0 93.049 55.959 A 5.313 5.313 0 0 0 92.7 57.9 A 5.185 5.185 0 0 0 93.352 60.571 Q 94.955 63.3 100.5 63.3 A 24.262 24.262 0 0 0 103.794 63.091 Q 105.459 62.863 106.815 62.383 A 9.973 9.973 0 0 0 109.25 61.15 A 7.752 7.752 0 0 0 111.109 59.348 A 6.428 6.428 0 0 0 112.3 55.5 Z"
          id="1"
        />
        <path
          className="letter"
          d="M 140.5 54.1 L 140.5 31 L 132.3 31 L 132.3 20 L 140.8 20 L 140.8 7 L 153.1 7 L 153.1 20 L 166.5 20 L 166.5 31 L 153.3 31 L 153.3 52.9 Q 153.3 56.324 154.219 58.067 A 4.016 4.016 0 0 0 154.75 58.85 A 3.848 3.848 0 0 0 156.109 59.806 Q 157.677 60.5 160.4 60.5 L 164.7 60.5 L 164.7 73 L 159.2 73 A 39.189 39.189 0 0 1 154.166 72.701 Q 147.543 71.84 144.5 68.5 Q 140.681 64.299 140.508 54.995 A 48.125 48.125 0 0 1 140.5 54.1 Z"
          id="2"
        />
        <path
          className="letter"
          d="M 178.2 54.1 L 178.2 31 L 170 31 L 170 20 L 178.5 20 L 178.5 7 L 190.8 7 L 190.8 20 L 204.2 20 L 204.2 31 L 191 31 L 191 52.9 Q 191 56.324 191.919 58.067 A 4.016 4.016 0 0 0 192.45 58.85 A 3.848 3.848 0 0 0 193.809 59.806 Q 195.377 60.5 198.1 60.5 L 202.4 60.5 L 202.4 73 L 196.9 73 A 39.189 39.189 0 0 1 191.866 72.701 Q 185.243 71.84 182.2 68.5 Q 178.381 64.299 178.208 54.995 A 48.125 48.125 0 0 1 178.2 54.1 Z"
          id="3"
        />
        <path
          className="letter"
          d="M 227.2 39.7 L 227.2 73 L 214.2 73 L 214.2 0 L 227.2 0 L 227.2 25.1 L 227.4 25.1 A 10.243 10.243 0 0 1 229.454 22.66 Q 230.816 21.455 232.75 20.45 A 17.493 17.493 0 0 1 237.103 18.847 A 14.991 14.991 0 0 1 240.3 18.5 A 22.429 22.429 0 0 1 246.169 19.227 A 16.263 16.263 0 0 1 253.8 23.7 A 17.433 17.433 0 0 1 258.262 32.313 A 25.574 25.574 0 0 1 258.8 37.7 L 258.8 73 L 245.8 73 L 245.8 40.6 A 14.322 14.322 0 0 0 245.49 37.533 Q 245.028 35.425 243.878 33.849 A 8.769 8.769 0 0 0 243.4 33.25 Q 241.052 30.56 236.599 30.501 A 15.151 15.151 0 0 0 236.4 30.5 Q 232.708 30.5 230.424 32.261 A 7.421 7.421 0 0 0 229.6 33 A 8.395 8.395 0 0 0 227.446 37.184 A 12.218 12.218 0 0 0 227.2 39.7 Z"
          id="4"
        />
        <path
          className="letter"
          d="M 306.7 54.7 L 315.8 62.7 Q 307.854 74.275 294.424 74.496 A 31.901 31.901 0 0 1 293.9 74.5 A 26.369 26.369 0 0 1 284.981 73.049 A 22.745 22.745 0 0 1 275.8 66.9 A 25.653 25.653 0 0 1 269.704 55.028 A 38 38 0 0 1 268.8 46.5 A 37.885 37.885 0 0 1 269.73 37.861 A 25.723 25.723 0 0 1 275.7 26.2 A 22.887 22.887 0 0 1 292.323 18.517 A 28.004 28.004 0 0 1 293.3 18.5 Q 303.9 18.5 310.7 26.2 A 25.773 25.773 0 0 1 316.709 38.031 A 38.043 38.043 0 0 1 317.6 46.5 L 317.6 50.4 L 282 50.4 A 20.829 20.829 0 0 0 282.493 54.096 Q 282.973 56.157 283.89 57.764 A 10.485 10.485 0 0 0 285.35 59.75 A 10.062 10.062 0 0 0 291.006 62.764 A 14.674 14.674 0 0 0 293.7 63 Q 297.9 63 300.85 60.95 A 17.728 17.728 0 0 0 303.239 58.93 Q 304.326 57.845 305.406 56.464 A 36.775 36.775 0 0 0 306.7 54.7 Z M 282 40.7 L 305 40.7 Q 304.5 35.6 301.45 32.75 A 10.297 10.297 0 0 0 296.495 30.222 A 15.054 15.054 0 0 0 293.3 29.9 A 11.896 11.896 0 0 0 289.369 30.526 A 10.276 10.276 0 0 0 285.5 32.95 A 11.706 11.706 0 0 0 282.1 39.824 A 15.084 15.084 0 0 0 282 40.7 Z"
          id="5"
        />
        <path
          className="letter"
          d="M 361.5 34.9 L 348.4 73 L 335.8 73 L 324.1 20 L 336.8 20 L 343.8 55.4 L 344.1 55.4 L 355.9 20 L 367.7 20 L 378.6 55.3 L 378.8 55.3 L 386.8 20 L 399.3 20 L 386.6 73 L 373.8 73 L 361.7 34.9 L 361.5 34.9 Z"
          id="6"
        />
      </g>
    </svg>
  );
};

export default SvgMatthewCopy;
