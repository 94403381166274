import React from "react";

const SvgBenson = () => {
  return (
    <svg
      className="lastName"
      viewBox="0 0 374.1 73.005"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="svgGroup"
        strokeLinecap="round"
        fillRule="evenodd"
        fontSize="9pt"
        stroke="#000"
        strokeWidth="0.25mm"
        fill="#000"
      >
        <path
          className="letter"
          d="M 26.7 70.001 L 0 70.001 L 0 0.001 L 29.7 0.001 A 26.028 26.028 0 0 1 35.953 0.711 A 18.106 18.106 0 0 1 44.6 5.501 Q 50.3 10.901 50.3 18.801 A 16.031 16.031 0 0 1 49.152 24.992 Q 47.6 28.737 43.951 31.295 A 19.37 19.37 0 0 1 42.7 32.101 L 42.7 32.201 Q 47.3 34.101 50.1 38.351 Q 52.9 42.601 52.9 48.201 Q 52.9 58.201 46.8 64.201 A 17.834 17.834 0 0 1 40.829 67.847 Q 35.584 69.865 27.763 69.992 A 65.419 65.419 0 0 1 26.7 70.001 Z M 29.9 39.201 L 13.5 39.201 L 13.5 57.501 L 29.8 57.501 A 14.321 14.321 0 0 0 33.541 57.056 Q 39.4 55.466 39.4 48.201 A 11.605 11.605 0 0 0 39.099 45.477 A 7.632 7.632 0 0 0 36.85 41.551 Q 34.394 39.288 30.223 39.204 A 16.098 16.098 0 0 0 29.9 39.201 Z M 28 12.501 L 13.5 12.501 L 13.5 28.201 L 28 28.201 Q 30.962 28.201 32.979 27.231 A 7.089 7.089 0 0 0 34.5 26.251 Q 36.751 24.343 36.799 20.663 A 12.362 12.362 0 0 0 36.8 20.501 A 10.374 10.374 0 0 0 36.319 17.196 Q 34.771 12.588 28.248 12.502 A 18.953 18.953 0 0 0 28 12.501 Z"
          id="0"
          fill="#fff"
          stroke="#fff"
        />
        <path
          className="letter"
          d="M 98.3 51.701 L 107.4 59.701 Q 99.454 71.276 86.024 71.497 A 31.901 31.901 0 0 1 85.5 71.501 A 26.369 26.369 0 0 1 76.581 70.049 A 22.745 22.745 0 0 1 67.4 63.901 A 25.653 25.653 0 0 1 61.304 52.029 A 38 38 0 0 1 60.4 43.501 A 37.885 37.885 0 0 1 61.33 34.862 A 25.723 25.723 0 0 1 67.3 23.201 A 22.887 22.887 0 0 1 83.923 15.518 A 28.004 28.004 0 0 1 84.9 15.501 Q 95.5 15.501 102.3 23.201 A 25.773 25.773 0 0 1 108.309 35.031 A 38.043 38.043 0 0 1 109.2 43.501 L 109.2 47.401 L 73.6 47.401 A 20.829 20.829 0 0 0 74.093 51.097 Q 74.573 53.158 75.49 54.764 A 10.485 10.485 0 0 0 76.95 56.751 A 10.062 10.062 0 0 0 82.606 59.765 A 14.674 14.674 0 0 0 85.3 60.001 Q 89.5 60.001 92.45 57.951 A 17.728 17.728 0 0 0 94.839 55.93 Q 95.926 54.846 97.006 53.465 A 36.775 36.775 0 0 0 98.3 51.701 Z M 73.6 37.701 L 96.6 37.701 Q 96.1 32.601 93.05 29.751 A 10.297 10.297 0 0 0 88.095 27.222 A 15.054 15.054 0 0 0 84.9 26.901 A 11.896 11.896 0 0 0 80.969 27.527 A 10.276 10.276 0 0 0 77.1 29.951 A 11.706 11.706 0 0 0 73.7 36.825 A 15.084 15.084 0 0 0 73.6 37.701 Z"
          id="1"
          fill="#fff"
          stroke="#fff"
        />
        <path
          className="letter"
          d="M 132.7 37.601 L 132.7 70.001 L 119.7 70.001 L 119.7 17.001 L 132.2 17.001 L 132.2 22.101 L 132.4 22.101 A 14.909 14.909 0 0 1 135.947 18.781 A 19.452 19.452 0 0 1 138.45 17.351 Q 142.3 15.501 146.4 15.501 A 23.712 23.712 0 0 1 152.205 16.172 A 16.28 16.28 0 0 1 160.2 20.751 Q 164.944 25.732 165.187 34.36 A 33.462 33.462 0 0 1 165.2 35.301 L 165.2 70.001 L 152.2 70.001 L 152.2 38.501 A 23.36 23.36 0 0 0 152.03 35.58 Q 151.613 32.278 150.171 30.358 A 6.911 6.911 0 0 0 150.05 30.201 A 6.537 6.537 0 0 0 147.089 28.139 Q 145.332 27.501 142.9 27.501 Q 138.408 27.501 135.755 29.91 A 8.579 8.579 0 0 0 135.4 30.251 A 9.222 9.222 0 0 0 132.926 35.096 A 13.277 13.277 0 0 0 132.7 37.601 Z"
          id="2"
          fill="#fff"
          stroke="#fff"
        />
        <path
          className="letter"
          d="M 174.7 52.501 L 186.4 49.501 Q 187.506 58.941 195.338 60.353 A 16.708 16.708 0 0 0 198.3 60.601 A 24.166 24.166 0 0 0 201.305 60.426 Q 204.057 60.08 205.95 59.051 A 6.676 6.676 0 0 0 207.391 58.02 A 4.419 4.419 0 0 0 208.8 54.701 A 4.04 4.04 0 0 0 208.552 53.246 Q 208.061 51.965 206.6 51.301 Q 204.64 50.245 200.406 48.963 A 112.343 112.343 0 0 0 197.4 48.101 A 6.018 6.018 0 0 1 197.146 48.042 Q 196.573 47.9 195.15 47.489 A 237.835 237.835 0 0 1 194.5 47.301 A 68.703 68.703 0 0 1 189.624 45.715 Q 184.819 43.936 181.919 41.845 A 16.795 16.795 0 0 1 180.9 41.051 A 11.483 11.483 0 0 1 176.731 32.681 A 15.257 15.257 0 0 1 176.7 31.701 A 14.652 14.652 0 0 1 181.185 21.081 A 19.989 19.989 0 0 1 182 20.301 A 18.146 18.146 0 0 1 189.547 16.355 Q 192.322 15.621 195.61 15.518 A 34.723 34.723 0 0 1 196.7 15.501 Q 213.479 15.501 218.44 29.362 A 29.062 29.062 0 0 1 219 31.101 L 208.2 34.401 A 17.615 17.615 0 0 0 206.913 31.614 Q 206.013 30.061 204.862 28.953 A 9.945 9.945 0 0 0 204.05 28.251 Q 201.4 26.201 196.8 26.201 A 16.106 16.106 0 0 0 194.422 26.366 Q 193.11 26.562 192.03 26.991 A 8.141 8.141 0 0 0 190.8 27.601 A 6.328 6.328 0 0 0 189.688 28.444 Q 189.022 29.087 188.729 29.844 A 3.454 3.454 0 0 0 188.5 31.101 Q 188.5 32.937 190.487 34.238 A 8.323 8.323 0 0 0 191.1 34.601 A 25.451 25.451 0 0 0 192.421 35.114 Q 195.869 36.363 204.4 38.801 Q 211.965 40.893 215.515 43.763 A 10.852 10.852 0 0 1 217 45.201 A 12.574 12.574 0 0 1 220.034 51.256 A 18.208 18.208 0 0 1 220.4 55.001 Q 220.4 61.401 214.8 66.501 Q 210.075 70.72 201.933 71.379 A 38.842 38.842 0 0 1 198.8 71.501 A 35.877 35.877 0 0 1 192.223 70.937 Q 188.522 70.245 185.621 68.708 A 17.788 17.788 0 0 1 181.8 66.001 Q 175.9 60.401 174.7 52.501 Z"
          id="3"
        />
        <path
          className="letter"
          d="M 234.1 23.001 A 23.779 23.779 0 0 0 232.98 24.253 Q 226.9 31.555 226.9 43.501 Q 226.9 56.801 233.9 64.101 A 23.511 23.511 0 0 0 242.179 69.765 A 25.399 25.399 0 0 0 251.7 71.501 A 30.463 30.463 0 0 0 252.365 71.494 A 23.533 23.533 0 0 0 269.5 64.101 A 22.275 22.275 0 0 0 272.416 60.317 Q 274.746 56.52 275.746 51.583 A 40.704 40.704 0 0 0 276.5 43.501 Q 276.5 30.501 269.3 23.001 Q 262.1 15.501 251.7 15.501 A 28.434 28.434 0 0 0 251.053 15.508 A 23.405 23.405 0 0 0 234.1 23.001 Z M 242.9 55.601 A 9.998 9.998 0 0 0 250.232 59.727 A 14.168 14.168 0 0 0 251.7 59.801 A 12.329 12.329 0 0 0 255.572 59.222 A 9.843 9.843 0 0 0 260.5 55.601 A 15.221 15.221 0 0 0 262.743 50.936 Q 263.341 48.921 263.565 46.52 A 32.481 32.481 0 0 0 263.7 43.501 Q 263.7 38.298 262.232 34.697 A 13.21 13.21 0 0 0 260.4 31.501 A 9.884 9.884 0 0 0 253.226 27.286 A 13.325 13.325 0 0 0 251.7 27.201 A 11.598 11.598 0 0 0 247.309 28.005 A 10.659 10.659 0 0 0 242.9 31.401 A 14.534 14.534 0 0 0 240.712 35.833 Q 240.096 37.832 239.854 40.242 A 32.785 32.785 0 0 0 239.7 43.501 Q 239.7 48.766 241.158 52.437 A 13.807 13.807 0 0 0 242.9 55.601 Z"
          id="4"
        />
        <path
          className="letter"
          d="M 300 37.601 L 300 70.001 L 287 70.001 L 287 17.001 L 299.5 17.001 L 299.5 22.101 L 299.7 22.101 A 14.909 14.909 0 0 1 303.247 18.781 A 19.452 19.452 0 0 1 305.75 17.351 Q 309.6 15.501 313.7 15.501 A 23.712 23.712 0 0 1 319.505 16.172 A 16.28 16.28 0 0 1 327.5 20.751 Q 332.244 25.732 332.487 34.36 A 33.462 33.462 0 0 1 332.5 35.301 L 332.5 70.001 L 319.5 70.001 L 319.5 38.501 A 23.36 23.36 0 0 0 319.33 35.58 Q 318.913 32.278 317.471 30.358 A 6.911 6.911 0 0 0 317.35 30.201 A 6.537 6.537 0 0 0 314.389 28.139 Q 312.632 27.501 310.2 27.501 Q 305.708 27.501 303.055 29.91 A 8.579 8.579 0 0 0 302.7 30.251 A 9.222 9.222 0 0 0 300.226 35.096 A 13.277 13.277 0 0 0 300 37.601 Z"
          id="5"
        />
      </g>
    </svg>
  );
};

export default SvgBenson;
